import { ReactElement } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Section } from '../components/styles/section.styled';

const NotFoundPage = (): ReactElement => (
  <Layout>
    <Section>
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Section>
  </Layout>
);

export default NotFoundPage;
